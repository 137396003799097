<script lang="ts">
    // ====================================================================================================

    export let html: string
</script>

<div id="container--docs">
    {@html html}
</div>

<style lang="postcss" global>
    #container--docs {
        @apply flex select-text flex-col gap-4;

        /* Lists */
        ol {
            @apply list-inside list-decimal;
            @apply space-y-1;
            @apply marker:text-step-400;
        }
        ul {
            @apply ml-4 list-outside list-disc;
            @apply space-y-1;
            @apply marker:text-step-400;
        }

        /* Embedded items */
        img,
        iframe {
            @apply m-auto rounded-xl border border-step-050 bg-step-100;
        }

        /* Divider lines */
        hr {
            @apply mx-1 my-5;
            @apply border-step-100;
        }

        /* Links */
        a {
            @apply underline decoration-step-300 decoration-2 underline-offset-2;
            @apply hover:text-blue-100 hover:decoration-blue-100;
            @apply active:text-blue-200 active:decoration-blue-200;
        }

        /* Styled Text */
        strong {
            @apply font-bold;
        }

        /* Highlighted "Bubbles" */
        kbd,
        code {
            @apply mx-[2px] rounded-lg border border-step-150 bg-step-100 px-[6px] py-[2px];
            @apply font-sans;
        }
        /* Use <kbd> for Buttons */
        kbd {
            @apply rounded-full;
        }

        /* Distance between headers and what is above them */
        p,
        ol,
        ul {
            @apply leading-relaxed;

            + h1,
            + h2,
            + h3,
            + h4,
            + h5,
            + h6 {
                @apply mt-4;
            }
        }

        /* Padding from left+right */
        & > h1,
        & > h2,
        & > h3,
        & > h4,
        & > h5,
        & > h6,
        & > pre,
        & > p:not(:has(img)):not(:has(iframe)), /* `> img` does not work */
        ol,
        ul {
            @apply break-words px-[6px];
        }

        /* Headings */
        & > h1 {
            @apply text-3xl font-semibold;
        }
        & > h2 {
            @apply text-2xl font-medium;
        }
        & > h3 {
            @apply text-xl font-medium;
        }
        & > h4 {
            @apply text-lg font-medium;
        }
    }
</style>
